﻿// ********************
// **  Site colours  **
// ********************

$brand--white:              #fff;
$brand--lightest-grey:      #f2f2f2;
$brand--light-grey:         #d1d0d0;
$brand--mid-grey:           #a6a6a6;
$brand--grey:               #7b7979;
$brand--dark-grey:          #454242;
$brand--black:              #231f1f;
$brand--blue:               #002f5f;
$brand--dark-blue:          #12202d;
$brand--red:                #e51c3b;
$brand--sky-blue:           #0073cf;
$brand--yellow:             #ffc300;
$brand--gold:               #9e833f;
$brand--green:              #2d9b66;

$brand--rafct-purple:       #4D3377;

$color--lightest-blue:      #d9eaf8;


// ******************************************************************
// **                                                              **
// **   BOOTSTRAP VARIABLES - (overriding bootstrap defaults)      **
// **                                                              **
// ******************************************************************


// set fundamental bootstrap colour variables

$primary:                       $brand--red;
$secondary:                     $brand--blue;
$light:                         $brand--white;
$dark:                          $brand--black;

$text-muted:                    $brand--dark-grey;

$success:                       $brand--green;
$info:                          $brand--yellow;
$warning:                       $brand--sky-blue;
$danger:                        $brand--red;

$body-bg:                       $light;
$body-color:                    $dark;

$link-color:                    $brand--sky-blue;
$link-decoration:               none;
$link-hover-color:              darken($brand--sky-blue, 10%);
$link-hover-decoration:         underline;


// Fonts

$font-family-serif:           'Lora', 'Times New Roman', Times, serif;
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

$font-family-base:            $font-family-serif;
$font-size-base:              1.125rem; // 18px - Assumes the browser default, typically `16px`
$font-weight-base:            400;
$line-height-base:            1.5;

$font-weight-bold:            700;

$headings-font-family:        'klavika-web', 'Times New Roman', Times, serif;
$headings-font-weight:        400; // medium - use 600 for 'display-*' classes
$headings-line-height:        1.2;
$headings-font-style:         normal;
$headings-letter-spacing:     0;

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
        // added
        6: (
            $spacer * 4,
        ),
        // added
        7:
            (
                $spacer * 5,
            ),
        // added
        8:
            (
                $spacer * 6,
            )
            // added,
    ),
    $spacers
);

// Buttons

$border-radius:                 0;
$border-radius-sm:              $border-radius;
$border-radius-lg:              $border-radius;

$btn-border-width:              2px;
$input-btn-font-size:           1rem;

// Tables

$table-cell-padding-x:        .5rem;
$table-cell-padding-y:        .75rem;
$table-cell-padding-x-sm:     .5rem;
$table-cell-padding-y-sm:     .5rem;
$table-border-color:          var(--theme--rule--color);



// ************************
// **                    **
// **   SITE VARIABLES   **
// **                    **
// ************************

$mask--solid:    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=);

// **************
// **  Panels  **
// **************

$panel--padding: (
    xs: 1rem,
    sm: 1.25rem,
    md: 1.5rem,
    lg: 1.5rem,
    xl: 1.5rem,
    xxl: 1.5rem
);

// ***************
// **  Header  **
// ***************

$header--desktop-logo-allowance:    280px;


// *****************
// **  Hero area  **
// *****************

$hero--fullscreen--height-reduction--mobile:             30px;
$hero--fullscreen--height-reduction--desktop:            30px;

$hero--img--min-heights: (
    xs: calc(var(--vpWidth) * .5625),
    sm: calc(var(--vpWidth) * .5625),
    md: 300px,
    lg: 350px,
    xl: 400px,
    xxl: 500px
);
$hero--img--heights: (
    xs: calc(var(--vpWidth) * .5625),
    sm: calc(var(--vpWidth) * .5625),
    md: calc(var(--vpWidth) * .5625),
    lg: var(--vpHeight),
    xl: var(--vpHeight),
    xxl: var(--vpHeight)
);
$hero--img--max-heights: (
    xs: calc(var(--vpWidth) * .5625),
    sm: calc(var(--vpWidth) * .5625),
    md: calc(var(--vpWidth) * .5625),
    lg: calc(var(--vpWidth) * .50),
    xl: calc(var(--vpWidth) * .33333),
    xxl: calc(var(--vpWidth) * .25)
);

// deeper

$hero--img--deeper--min-heights: (
    xs: calc(var(--vpHeight) - var(--total-header-height)),
    sm: calc(var(--vpHeight) - var(--total-header-height)),
    md: calc(var(--vpHeight) - var(--total-header-height)),
    lg: calc(var(--vpHeight) - var(--total-header-height)),
    xl: calc(var(--vpHeight) - var(--total-header-height)),
    xxl: calc(var(--vpHeight) - var(--total-header-height))
);
$hero--img--deeper--heights: (
    xs: calc(var(--vpHeight) - var(--total-header-height)),
    sm: calc(var(--vpHeight) - var(--total-header-height)),
    md: calc(var(--vpHeight) - var(--total-header-height)),
    lg: calc(var(--vpHeight) - var(--total-header-height)),
    xl: calc(var(--vpHeight) - var(--total-header-height)),
    xxl: calc(var(--vpHeight) - var(--total-header-height))
);
$hero--img--deeper--max-heights: (
    xs: calc(var(--vpHeight) - var(--total-header-height)),
    sm: calc(var(--vpHeight) - var(--total-header-height)),
    md: calc(var(--vpHeight) - var(--total-header-height)),
    lg: calc(var(--vpHeight) - var(--total-header-height)),
    xl: calc(var(--vpHeight) - var(--total-header-height)),
    xxl: calc(var(--vpHeight) - var(--total-header-height))
);

// ***************************
// **  50/50 split layouts  **
// ***************************

$split-img-content--content--margin--lg:    30px;
$split-img-content--content--margin--xl:    60px;
$split-img-content--content--margin--xxl:   90px;

// ***********************
// **  Image galleries  **
// ***********************

$image-gallery--small-thumb-height: 120px;
$image-gallery--medium-thumb-height: 180px;
$image-gallery--large-thumb-height: 240px;
$image-gallery--thumb--margin: 5px;
$image-gallery--thumb--bg-color: $brand--black;

// ******************
// **  Countdowns  **
// ******************

// All sizes are in em units as they are relative to the font size
$countdown--font-size--default: 1.8rem;
$countdown--font-size--large: 2.4rem;
$countdown--font-size--small: 1.2rem;
// digits
$countdown--digit--font-family: $font-family-base;
$countdown--digit--font-weight: 500;
$countdown--digit--font-style: normal;
// column labels
$countdown--column-label--height: 0.4em; // use 'em' units 
$countdown--column-label--font-size: 0.4em; // use 'em' units
$countdown--column-label--font-family: $headings-font-family;
$countdown--column-label--font-weight: $headings-font-weight;
$countdown--column-label--font-style: $headings-font-style;
$countdown--column-label--text-transform: none;
$countdown--column-label--text-align: right;
$countdown--column-label--transform: translateX(-.15em);
// color settings (using themes as default)
$countdown--digit--bgcolor: transparent;
$countdown--digit--color: var(--theme--color);
$countdown--digit--border-color: var(--theme--color--subtle);
$countdown--column-label--color: var(--theme--color--subtle);

// *****************
// **  Expanders  **
// *****************

$expander--padding-top:                     15px;
$expander--padding-right:                   15px;
$expander--padding-bottom:                  15px;
$expander--padding-left:                    15px;
$expander--gap:                             1rem;
$expander--panel-divider--border-top:       1px solid var(--theme--rule--color);
$expander--panel-divider--padding-top:      15px;
