.btn-unstyled {
    appearance: none;
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: inherit;
    color: inherit;
    cursor: pointer;
    background-color: transparent;
}

a, .btn, button {
    svg.fa-arrow-right, svg.fa-angle-right, svg.fa-arrow-left, svg.fa-angle-left, svg.fa-arrow-down, svg.fa-angle-down, svg.riat-custom-icon  {
        will-change: transform;
        transition: transform 300ms ease-in-out;
    }
    svg.fa-arrow-right, svg.fa-angle-right, svg.riat-custom-icon.arrow-right {
        margin-left: 0.3em;
    }
    svg.fa-arrow-left, svg.fa-angle-left, svg.riat-custom-icon.arrow-left {
        margin-right: 0.3em;
    }
    svg.fa-arrow-down, svg.fa-angle-down, svg.riat-custom-icon.arrow-down  {
        margin-left: 0.3em;
    }
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus {
            svg.fa-arrow-right, svg.fa-angle-right, svg.riat-custom-icon.arrow-right {
                transform: translate3d(0.3em,0,0);
            }
            svg.fa-arrow-left, svg.fa-angle-left, svg.riat-custom-icon.arrow-left {
                transform: translate3d(-0.3em,0,0);
            }
        }
        &[aria-expanded="true"] {
            svg.fa-arrow-down, svg.fa-angle-down, svg.riat-custom-icon.arrow-down  {
                transform: scale(1) rotate(180deg);
            }
        }
    }
    &:disabled, &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
}

.btn {
    font-family: $headings-font-family;
    font-weight: 600;
    text-transform: uppercase;
    &.btn-link {
        font-family: inherit;
        font-weight: inherit;
        line-height: inherit;
        font-size: inherit;
        letter-spacing: inherit;
        text-transform: inherit;
        &.btn-sm {
            font-size: 0.875rem;
        }
        &.p-0 {
            vertical-align: baseline;
        }
    }
    &.btn-primary {
        background-color: var(--theme--btn--primary--bg-color);
        border-color: var(--theme--btn--primary--bg-color);
        color: var(--theme--btn--primary--color);
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus {
                background-color: var(--theme--btn--primary--bg-color--hover);
                border-color: var(--theme--btn--primary--border-color--hover);
                color: var(--theme--btn--primary--color--hover);
            }
        }
    }
    &.btn-secondary {
        background-color: var(--theme--btn--secondary--bg-color);
        border-color: var(--theme--btn--secondary--bg-color);
        color: var(--theme--btn--secondary--color);
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus {
                background-color: var(--theme--btn--secondary--bg-color--hover);
                border-color: var(--theme--btn--secondary--border-color--hover);
                color: var(--theme--btn--secondary--color--hover);
            }
        }
    }
    &.btn-outline-primary {
        border-color: var(--theme--btn--primary--bg-color);
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus {
                background-color: var(--theme--btn--primary--bg-color--hover);
                border-color: var(--theme--btn--primary--border-color--hover);
                color: var(--theme--btn--primary--color--hover);
            }
        }
    }
    &.btn-outline-secondary {
        border-color: var(--theme--btn--secondary--bg-color);
        color: var(--theme--btn--secondary--bg-color);
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus {
                background-color: var(--theme--btn--secondary--bg-color--hover);
                border-color: var(--theme--btn--secondary--border-color--hover);
                color: var(--theme--btn--secondary--color--hover);
            }
        }
    }
    &.btn-success {
        color: $brand--white;
    }
}

button:not(:disabled) span.spinner-border {
    display: none;
}

button:disabled span.spinner-border {
    display: inline-block;
}
