.header--content--menu {

    // mobile version

    @include media-breakpoint-down(lg) {

        display: block;
        opacity: 0;
        pointer-events: hidden;
        position: fixed;
        top: calc(var(--top-utility-bar--height) + var(--header--height));
        left: 0;
        width: 100%;
        padding: 60px 0;
        height: calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height)));
        overflow-y: auto;
        background-color: white;
        will-change: opacity;

        &[aria-hidden="true"] {
            display: none;
            opacity: 0;
        }
        .mobile-menu-active & {
            opacity: 1;
            pointer-events: all;
        }
        .mobile-menu-fading & {
            transition: opacity 300ms linear;
        }

        nav {
            --theme--link--color: #{$link-color};
            --theme--link--color--hover: #{$link-hover-color};
            margin: 0 auto;
            width: calc(100vw - 60px);
            max-width: 400px;
            & > ul.menu {
                display: block;
                width: 100%;
                margin: 0;
                padding: 0;
                list-style: none;
                @include media-breakpoint-up(md) {
                    max-width: 400px;
                }
        
                & > li {
                    display: block;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    overflow: hidden;
                    border-bottom: 1px solid $brand--mid-grey;
                    &:first-child {
                        border-top: 1px solid $brand--mid-grey;
                    }

                    & > a, & > button {
                        appearance: none;
                        display: block;
                        width: 100%;
                        margin: 0;
                        padding: 15px 0;
                        border: 0;
                        text-align: left;
                        font-family: $headings-font-family;
                        font-weight: $headings-font-weight;
                        text-transform: uppercase;
                        text-decoration: none;
                        font-size: 1.4rem;
                        line-height: 1.6rem;
                        background-color: transparent;
                        color: var(--theme--link--color);
                        
                        &:hover, &:focus {
                            color: var(--theme--link--color--hover);
                        }
                    }
                }

                button {
                    position: relative;
                    .submenu--toggle--indicator {
                        position: absolute;
                        top: 18px;
                        right: 0;
                        margin-left: 0.5em;
                        color: var(--theme--link--color);
                        will-change: color;
                        transition: color 200ms linear;
                        font-size: .8rem;
                    }
                    &:hover, &:not([aria-expanded="true"]):focus {
                        color: var(--theme--link--color--hover);
                        text-decoration: underline;
                    }
                    &[aria-expanded="true"] {
                        color: var(--theme--link--color--hover);
                        .submenu--toggle--indicator {
                            color: $brand--sky-blue;
                        }
                    }
                }

                /* submenu level */
        
                .submenu {
                    --theme--link--color: #{$brand--dark-grey};
                    --theme--link--color--hover: #{$link-hover-color};
                    overflow: hidden;
                    max-height: 0;
                    transition: max-height 300ms ease-in-out;
                    .submenu--canonical-link {
                        margin: 0 0 20px;
                        padding-top: 10px;
                        a {
                            font-family: $font-family-base;
                            font-size: 18px;
                            line-height: 1.2;
                            svg {
                                font-size: 16px;
                            }
                        }
                    }
                    .submenu--content-area--section {
                        margin: 0;
                        padding: 0 0 20px;
                        h3 {
                            font-family: $headings-font-family;
                            font-weight: 700;
                            text-transform: uppercase;
                            font-size: 14px;
                            color: $brand--red;
                            
                        }
                        ul {
                            display: block;
                            width: 100%;
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            li {
                                display: block;
                                width: 100%;
                                margin: 0 0 10px;
                                padding: 0;
                                list-style: none;
                                a, button {
                                    font-family: $font-family-base;
                                    font-size: 18px;
                                    line-height: 1.2;
                                    text-transform: none;
                                }
                            }
                        }
                    }
                }
            }
            .mobile--top-cta {
                margin-bottom: 30px;
            }
            .mobile--utility-links {
                margin-top: 30px;
                --theme--btn--secondary--bg-color: #{$brand--mid-grey};
                --theme--btn--secondary--border-color: #{$brand--mid-grey};
                --theme--btn--secondary--color: #{$brand--white};
                --theme--btn--secondary--bg-color--hover: color-mix(in srgb, black 50%, var(--brand--accent-color));
                --theme--btn--secondary--border-color--hover: color-mix(in srgb, black 50%, var(--brand--accent-color));
                --theme--btn--secondary--color--hover: #{$brand--white};
                ul {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 15px;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    li {
                        display: block;
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        .btn-sm {
                            --bs-btn-font-size: max(.8rem, 14px);
                        }
                    }
                }
            }
        }

    }

    // desktop version

    @include media-breakpoint-up(lg) {
        display: block;
        flex: 1 1 auto;
        --submenu-left: 0px;
        --submenu-width-limit: calc(var(--vpWidth) - (#{$header--desktop-logo-allowance} + (2 * var(--edge-padding))));
        --submenu-min-left: calc(#{$header--desktop-logo-allowance} + var(--edge-padding));
        --submenu-section-width: 286px;
        @include media-breakpoint-up(xxl) {
            --submenu-width-limit: calc(#{map-get($container-max-widths, "xxl")} - (#{$header--desktop-logo-allowance} + (2 * var(--edge-padding))));
            --submenu-min-left: calc(((var(--vpWidth) - #{map-get($container-max-widths, "xxl")}) / 2) + (#{$header--desktop-logo-allowance} + var(--edge-padding)));
        }

        nav {
            & > ul.menu {
                display: flex;
                gap: 15px;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin: 0;
                padding: 0;
                list-style: none;
        
                & > li {
                    display: block;
                    flex: 0 0 auto;
                    margin: 0;
                    padding: 0;
                    overflow: hidden;
                    list-style: none;
                    text-align: center;

                    & > a, & > button {
                        appearance: none;
                        display: inline-block;
                        position: relative;
                        margin: 0;
                        padding: 15px 2px;
                        border: 0;
                        text-align: center;
                        font-family: $headings-font-family;
                        font-weight: $headings-font-weight;
                        text-transform: uppercase;
                        text-decoration: none;
                        font-size: 16px;
                        line-height: 18px;
                        background-color: transparent;
                        color: var(--theme--link--color);
                        .submenu--toggle--indicator {
                            margin-left: 0.5em;
                            color: var(--theme--link--color);
                            will-change: color;
                            transition: color 200ms linear;
                            font-size: .5rem;
                            vertical-align: middle;
                        }
                        &:hover, &:not([aria-expanded="true"]):focus {
                            color: var(--theme--link--color--hover);
                            text-decoration: underline;
                        }
                        &[aria-expanded="true"] {
                            .submenu--toggle--indicator {
                                color: $brand--sky-blue;
                            }
                        }
                    }

                    .submenu {
                        --theme--link--color: #{$dark};
                        --theme--link--color--hover: #{$link-hover-color};

                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                        position: fixed;
                        top: calc(var(--top-utility-bar--height) + var(--header--height));
                        left: var(--submenu-left);
                        width: max-content;
                        max-width: var(--submenu-width-limit);
                        max-height: calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height)));
                        background-color: white;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
                        opacity: 0;
                        will-change: opacity;
                        text-align: left;

                        &[aria-hidden="true"] {
                            display: none;
                            pointer-events: hidden;
                            opacity: 0;
                        }
                        .desktop-menu-active & {
                            opacity: 1;
                            pointer-events: all;
                        }
                        .desktop-menu-fading & {
                            transition: opacity 200ms linear;
                        }

                        .submenu--content-area {
                            position: relative;
                            width: 100%;
                            padding: 40px var(--edge-padding);

                            .submenu--canonical-link {
                                margin: 0 0 30px;
                                a {
                                    font-family: $font-family-base;
                                    font-weight: $font-weight-bold;
                                    font-size: 18px;
                                    svg {
                                        font-size: 16px;
                                    }
                                }
                            }

                            .submenu--content-area--sections {
                                --gap-y: 20px;
                                --gap-x: 30px;
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                align-items: stretch;
                                gap: var(--gap-y) var(--gap-x);
                                width: max-content;
                                max-width: 100%;
                                &:has(.submenu--content-area--section:nth-child(2)) {
                                    --submenu-section-width: calc((var(--submenu-width-limit) - ((2 * var(--edge-padding)) +  var(--gap-x))) / 2);
                                }
                                @include media-breakpoint-up(xl) {
                                    &:has(.submenu--content-area--section:nth-child(2)) {
                                        --submenu-section-width: calc((var(--submenu-width-limit) - ((2 * var(--edge-padding)) +  (2 * var(--gap-x)))) / 3);
                                    }
                                }
                                @include media-breakpoint-up(xxl) {
                                    &:has(.submenu--content-area--section:nth-child(3)) {
                                        --submenu-section-width: calc((var(--submenu-width-limit) - ((2 * var(--edge-padding)) +  (2 * var(--gap-x)))) / 3);
                                    }
                                }

                                .submenu--content-area--section {
                                    width: var(--submenu-section-width);
                                    h3 {
                                        font-family: $headings-font-family;
                                        font-weight: 700;
                                        text-transform: uppercase;
                                        font-size: 14px;
                                        color: $brand--red;
                                        
                                    }

                                    ul {
                                        margin: 0;
                                        padding: 0;
                                        list-style: none;

                                        li {
                                            margin: 0 0 8px;
                                            padding: 0;
                                            list-style: none;
                                            &:last-child {
                                                margin-bottom: 0;
                                            }

                                            a {
                                                font-size: 16px;
                                            }
                                        }

                                    }
                                    
                                }

                            }

                            .submenu--close {
                                position: absolute;
                                top: 22px;
                                right: calc(var(--edge-padding) - #{$input-btn-padding-x});
                                color: $brand--mid-grey;
                                font-size: 20px;
                                &:hover, &:focus {
                                    color: var(--theme--link--color--hover);
                                }
                            }

                        }
                        
                    }
                }
            }
        }
        .submenu--menu-indicator {
            position: fixed;
            top: calc(var(--top-utility-bar--height) + var(--header--height) - 15px);
            left: 0;
            pointer-events: none;
            width: 30px;
            height: 15px;
            overflow: hidden;
            &:before {
                display: block;
                content: '';
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid white;
                opacity: 0;
                transform: translate3d(0,15px,0);
                transition: transform 300ms ease-in-out, opacity 200ms linear;
                will-change: transform;
            }
            .desktop-menu-active &:before {
                opacity: 1;
                transform: translate3d(0,0,0);
                transition: transform 300ms ease-in-out, opacity 0s linear;
                transition-delay: 200ms;
            }
        }
    }
}
