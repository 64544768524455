// light themes

.theme--white, .theme--lightest-grey {
    @include on-light-theme-vars;
}
.theme--white {
    --theme--background-color: #{$brand--white};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
}
.theme--lightest-grey {
    --theme--background-color: #{$brand--lightest-grey};
    --theme--color--emphasis--invert: #{$brand--white};
    background: var(--theme--background-color);
}

.theme--lightest-blue {
    --theme--background-color: #{$color--lightest-blue};
    --theme--color--emphasis--invert: #{$brand--white};
    --theme--rule--color: #{darken($color--lightest-blue, 10%)};;
    background: var(--theme--background-color);
}

// dark themes

.bg--transparent {
    --theme--background-color: transparent;
    background: transparent !important;
}

.theme--black, .theme--blue, .theme--red, .theme--rafct-purple {
    @include on-dark-theme-vars;
}
.theme--black {
    --theme--background-color: #{$brand--black};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
}
.theme--blue {
    --theme--background-color: #{$brand--blue};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
}
.theme--red { // just for emregency message
    --theme--background-color: #{$brand--red};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
}
.theme--rafct-purple {
    --theme--background-color: #{$brand--rafct-purple};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
}
