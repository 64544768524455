:root {

    --vpHeight: 100vh;
    --vpWidth: 100vw;
    
    // ******************************
    // *****  layout variables  *****
    // ******************************


    --top-utility-bar--height: 0px;
    --header--height: 70px;
    --total-header-height: 0px;
    --edge-padding: 15px;
    --full-height--adjustment: #{$hero--fullscreen--height-reduction--mobile}; // use to show some content below 'full-height' element;
    --brand--accent-color: #{$brand--blue};

    @include media-breakpoint-up(sm) {
        --edge-padding: 30px;
        // bootstrap column spacing
        .row {
            --bs-gutter-x: 2rem;
            .col {
                --bs-gutter-x: #{$grid-gutter-width};
            }
        }
    }

    @include media-breakpoint-up(lg) {
        --top-utility-bar--height: 55px;
        --header--height: 55px;
        --full-height--adjustment: #{$hero--fullscreen--height-reduction--desktop};
    }
    
    // ***********************************
    // *****  theming css variables  *****
    // ***********************************
    body.site-layout, .module--demo-area {
        // apply default theme rules
        --theme--background-color: #{$brand--white};
        @include on-light-theme-vars;
    }
    .module--demo-area {
        background: var(--theme--background-color);
        color: var(--theme--color);
        padding: 30px 0;
    }

}

// body

body.site-layout {
    --total-header-height: calc(var(--top-utility-bar--height) + var(--header--height));
    display: flex;
    flex-direction: column;
    min-height: var(--vpHeight);
    padding-top: var(--total-header-height);
    color: var(--theme--color);
    scroll-behavior: smooth;
    &.rafct {
        --brand--accent-color: #{$brand--rafct-purple};
    }

    #main-content {
        flex: 1 1 auto;
        [id] {
            scroll-margin-top: calc(var(--total-header-height) + var(--panel--padding));
        }
    }

    #main-footer {
        flex: 0 0 auto;
        [id] {
            scroll-margin-top: calc(var(--total-header-height) + var(--panel--padding));
        }
    }

    .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
        padding-left: var(--edge-padding);
        padding-right: var(--edge-padding);
    }
}

// Link colours

a:not(.btn, .dropdown-item, .page-link, .visual-listing--item), .btn.btn-link {
    color: var(--theme--link--color);
    text-decoration: var(--theme--link--text-decoration);
    &:hover, &:focus {
        color: var(--theme--link--color--hover);
        text-decoration: var(--theme--link--text-decoration--hover);
    }
    main p > &:not([class="*"]) {
        text-decoration: underline;
        text-decoration-style: var(--theme--link--text-decoration--style);
        text-decoration-color: var(--theme--link--text-decoration--color);
        &:hover, &:focus {
            text-decoration-style: var(--theme--link--text-decoration--style--hover);
            text-decoration-color: var(--theme--link--text-decoration--color--hover);
        }
    }
}

// temporary sizing for font-awesome whilst loading
body:not(.fontawesome-i2svg-complete) {
    i.fa-solid, i.fa-sharp, i.fa-regular, i.fa-thin, i.fa-light, i.fa-duotone, i.fa-brands {
        display: inline-block !important;
        min-width: 1em !important;
        min-height: 1em !important;
        vertical-align: -0.125em !important;
    }
}

// emergency message

.emergency-message {
    position: fixed !important;
    z-index: 9 !important;
    top: var(--total-header-height);
    left: 0;
    width: 100%;
    border: 0;
    max-height: calc(90vh - (var(--total-header-height)));
    overflow-y: auto;
    transition: transform 300ms ease-in-out;
    will-change: transform;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    text-transform: uppercase;
    .warning-icon {
        min-height: 2.5em;
    }
    .header-hidden:not(.mobile-menu-active) & {
        transform: translate3d(0,calc(-1 * (var(--total-header-height))),0);
    }
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

// page-title-area
.breadcrumb {
    background-color: transparent;
    margin: 0;
    font-size: 0.875rem;
    .breadcrumb-item {
        &:not(:first-child):before {
            content: '';
            display: inline-block;
            vertical-align: baseline;
            height: 95%;
            width: 1em;
            background: no-repeat center url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512' class='svg-inline--fa fa-angle-right fa-w-6'%3E%3Cpath fill='%230073cf' d='M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z' class=''%3E%3C/path%3E%3C/svg%3E");
            background-size: auto 80%;
            background-position: center left;
        }
        a {
            color: $brand--grey;
            &:hover, &:focus {
                color: var(--theme--link--color--hover);
                text-decoration: underline;
            }
        }
        &.active {
            color: $brand--blue;
            font-weight: 600 !important;
        }
    }
}
.page-title-area {
    .page-title-area--breadcrumb {
        @include media-breakpoint-up(lg) {
            padding-top: 100px; // allow for logo
        }
    }
    @include media-breakpoint-up(lg) {
        #checkout-time-remaining + & {
            .page-title-area--breadcrumb {
                padding-top: 55px; // allow for logo
            }
        }
    }
    .page-title-area--title {
        h1 {
            margin-top: 0.5rem;
            word-break: keep-all;
        }
    }

    .hero + & {
        .page-title-area--breadcrumb {
            padding-top: 0;
        }
        .page-title-area--title {
            position: absolute;
            z-index: 2;
            left: 0;
            width: 100%;
            top: calc(var(--total-header-height) + (#{map-get($hero--img--heights, xs)} / 2));
            h1 {
                margin: 0;
                color: $brand--white;
                line-height: 1.05;
                filter: drop-shadow(0 0 3px rgba(black, 0.2));
                transform: translateY(-50%);
            }
            @include media-breakpoint-up(sm) {
                top: calc(var(--total-header-height) + (#{map-get($hero--img--heights, sm)} / 2));
            }
            @include media-breakpoint-up(md) {
                top: calc(var(--total-header-height) + (#{map-get($hero--img--heights, md)} / 2));
                h1 {
                    max-width: 500px;
                }
            }
            @include media-breakpoint-up(lg) {
                top: calc(var(--total-header-height) + 120px);
                h1 {
                    max-width: max(500px, 50%);
                    line-height: 1.1;
                    transform: none;
                }
            }
            &.page-title-area--title--dark {
                h1 {
                    width: fit-content;
                    position: relative;
                    z-index: 2;
                    filter: none;
                    color: $brand--blue;
                    text-shadow:    -2px -2px 20px white,
                                    -2px 2px 20px white,
                                    2px -2px 20px white,
                                    2px 2px 20px white,
                                    -2px -2px 30px white,
                                    -2px 2px 30px white,
                                    2px -2px 30px white,
                                    2px 2px 30px white;
                    filter: drop-shadow(0 0 30px white);
                }
            }
        }
    }
}

// rules

@keyframes indicator-reveal {
    from {
        opacity: 1;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.basket-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 17px;
    min-height: 17px;
    padding: 1px;
    border-radius: 50%;
    font-size: 11px;
    line-height: 1;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    background-color: $brand--red;
    color: $brand--white;
    transform: scale(0);
    will-change: transform;
    opacity: 0;
    animation: indicator-reveal;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
}

// rules

hr {
    border-color: var(--theme--rule--color);
    opacity: 1;
}

// max-widths (mostly for form inputs
.mw-100px {
    max-width: 100px;
}
.mw-200px {
    max-width: 200px;
}
.mw-300px {
    max-width: 300px;
}
.mw-400px {
    max-width: 400px;
}
.mw-500px {
    max-width: 500px;
}

// fullscreen-top-section
.fullscreen-top-section {
    display: flex;
    flex-direction: column;
    --top-section-min-height: max(#{map-get($hero--img--min-heights, xs)}, calc(var(--vpHeight) - (var(--total-header-height) + var(--full-height--adjustment))));
    .with-promo-bar & {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, xs)}, calc(var(--vpHeight) - (var(--total-header-height) + var(--promo-bar--height) + var(--full-height--adjustment))));
    }
    min-height: var(--top-section-min-height);
    @include media-breakpoint-up(sm) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, sm)}, calc(var(--vpHeight) - (var(--total-header-height) + var(--full-height--adjustment))));
        .with-promo-bar & {
            --top-section-min-height: max(#{map-get($hero--img--min-heights, sm)}, calc(var(--vpHeight) - (var(--total-header-height) + var(--promo-bar--height) + var(--full-height--adjustment))));
        }
        min-height: var(--top-section-min-height);
    }
    @include media-breakpoint-up(md) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, md)}, calc(var(--vpHeight) - (var(--total-header-height) + var(--full-height--adjustment))));
        .with-promo-bar & {
            --top-section-min-height: max(#{map-get($hero--img--min-heights, md)}, calc(var(--vpHeight) - (var(--total-header-height) + var(--promo-bar--height) + var(--full-height--adjustment))));
        }
        min-height: var(--top-section-min-height);
    }
    @include media-breakpoint-up(lg) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, lg)}, calc(var(--vpHeight) - (var(--total-header-height) + var(--full-height--adjustment))));
        .with-promo-bar & {
            --top-section-min-height: max(#{map-get($hero--img--min-heights, lg)}, calc(var(--vpHeight) - (var(--total-header-height) + var(--promo-bar--height) + var(--full-height--adjustment))));
        }
        min-height: var(--top-section-min-height);
    }
    @include media-breakpoint-up(xl) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, xl)}, calc(var(--vpHeight) - (var(--total-header-height) + var(--full-height--adjustment))));
        .with-promo-bar & {
            --top-section-min-height: max(#{map-get($hero--img--min-heights, xl)}, calc(var(--vpHeight) - (var(--total-header-height) + var(--promo-bar--height) + var(--full-height--adjustment))));
        }
        min-height: var(--top-section-min-height);
    }
    @include media-breakpoint-up(xxl) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, xxl)}, calc(var(--vpHeight) - (var(--total-header-height) + var(--full-height--adjustment))));
        .with-promo-bar & {
            --top-section-min-height: max(#{map-get($hero--img--min-heights, xxl)}, calc(var(--vpHeight) - (var(--total-header-height) + var(--promo-bar--height) + var(--full-height--adjustment))));
        }
        min-height: var(--top-section-min-height);
    }
}

// section--scroll-reveal
.section--scroll-reveal {
    opacity: 0;
    will-change: opacity, transform;
    &--item {
        transform: translateY(40px);
        will-change: transform;
    }
    &.hunt-in {
        opacity: 1;
        transition: opacity 750ms linear;
        .section--scroll-reveal--item {
            transform: translateY(0);
            transition: transform 1s ease-out;
        }
    }
}

// column-vertical-rule
.column-vertical-rule {
    position: relative;
    &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 1px;
        background-color: var(--theme--rule--color);
    }
}

// sticky-side-wrapper
.sticky-side-wrapper {
    position: relative;
    .sticky-side-content {
        display: block;
        position: sticky;
        top: calc(var(--total-header-height) + var(--panel--padding));
        transition: top 300ms ease-in-out;
        will-change: transform;
        .header-hidden & {
            top: var(--panel--padding);
        }
    }
}

// full-width--breakout (make content that has to be inside a column but needs to be full width)

.full-width-breakout {
    display: block;
    width: 100%;
    --breakout-width: var(--vpWidth);
    &--inner {
        display: block;
        position: relative;
        width: var(--breakout-width);
        margin: 0 0 0 calc((var(--breakout-width) - 100%) * -.5) !important;
    }
}

// featured-quote
.featured-quote {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    overflow: hidden;
    --quote-size: clamp(60px, 20vw, 400px);
    text-align: center;
    &:before, &:after {
        display: block;
        content: '“';
        position: absolute;
        top: -.125em;
        left: 0;
        font-size: var(--quote-size);
        line-height: 1;
        font-family: $headings-font-family;
        font-weight: 600;
        color: var(--theme--accent--color);
        pointer-events: none;
        opacity: .4;
    }
    &:after {
        content: '”';
        left: auto;
        right: 0;
    }
    strong {
        display: block;
        padding: 0 calc(var(--quote-size) * .6);
        font-size: clamp(18px, 4vw, 2.6rem);
        font-weight: 400;
    }
    cite {
        display: block;
        color: var(--theme--btn--secondary--bg-color);
    }
}

// wide-image-text-layout

.wide-image-text-layout {
    --img-max-width: 300px;
    display: block;
    .wide-image-text-layout--image {
        display: block;
        margin-bottom: 15px;
    }
    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        gap: var(--panel--padding);
        align-items: stretch;
        .wide-image-text-layout--image {
            position: relative;
            flex: 0 0 auto;
            min-height: 100%;
            width: 50%;
            max-width: var(--img-max-width);
            margin: 0;
            &:before { // minimum ratio 16:9
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 56.25%;
            }
            .img-scale-wrapper, img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .wide-image-text-layout--content {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            min-height: 100%;
            justify-content: center;
            align-items: flex-start;
            .wide-image-text-layout--content--inner {
                width: 100%;
                & > *:last-child {
                    margin-bottom: 0;
                }
                .panel--cta {
                    margin-bottom: 0;
                }
                ul.taglist {
                    margin: 0 0 10px;
                    justify-content: flex-end;
                    @include media-breakpoint-up(md) {
                        float: right;
                        margin: 0 0 15px 15px;
                        max-width: 50%;
                    }
                    @include media-breakpoint-up(lg) {
                        max-width: 33%;
                    }
                    @include media-breakpoint-up(xl) {
                        max-width: 25%;
                    }
                }
            }

        }
        // image right
        &.wide-image-text-layout--image-right {
            .wide-image-text-layout--image {
                order: 2;
            }
            .wide-image-text-layout--content {
                order: 1;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        --img-max-width: 400px;
    }
    // opening times modifiers
    .opening-times & {
        --img-max-width: 200px;
        @include media-breakpoint-up(xl) {
            --img-max-width: 300px;
        }
    }
}

// 50/50 layout with optional carousel

.split-img-content-layout {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - (2 * var(--edge-padding)));
    --content-container--margin-left: 0;
    --content-container--margin-right: 0;
    --content-container--max-width: none;
    .split-img-content-layout--img {
        background-color: var(--dark);
        width: 100%;
    }
    .split-img-content-layout--content {
        width: 100%;
        .split-img-content-layout--content--inner {
            .container { // set max-widths to align with other page elements
                margin-left: var(--content-container--margin-left);
                margin-right: var(--content-container--margin-right);
                max-width: var(--content-container--max-width);
                width: auto;
            }
        }
    }
    @include media-breakpoint-up(md) {
        --content-container--max-width: 83%;
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: stretch;
        --content-container--margin-left: #{$split-img-content--content--margin--lg};
        --content-container--margin-right: #{$split-img-content--content--margin--lg};
        --content-container--max-width: none;
        .split-img-content-layout--img {
            position: relative;
            width: 50%;
            min-height: 100%;
            overflow: hidden;
            &:before { /* used to set the minimum section height to 4:3 image height */
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 75%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: calc(100% + 1px);
                object-fit: cover;
                font-family: 'object-fit: cover';
            }
            .carousel {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                .carousel-inner, .carousel-item {
                    height: 100%;
                    overflow: hidden;
                }
            }
        }
        .split-img-content-layout--content {
            display: flex;
            width: 50%;
            min-height: 100%;
            align-items: center;
            .split-img-content-layout--content--inner {
                width: 100%;
            }
        }

        &.split-img-content-layout--img-right {
            flex-direction: row-reverse;
            .split-img-content-layout--content {
                .container { // set max-widths to align with other page elements
                    margin-left: var(--content-container--margin-right);
                    margin-right: var(--content-container--margin-left);
                }
            }
        }
    }
    @include media-breakpoint-up(xl) {
        --content-container--margin-left: #{$split-img-content--content--margin--xl};
        --content-container--margin-right: #{$split-img-content--content--margin--xl};
    }
    @include media-breakpoint-up(xxl) {
        width: min(calc(100% - (2 * var(--edge-padding))), calc(#{map-get($container-max-widths, xxl)} - (2 * var(--edge-padding))));
        --content-container--margin-left: #{$split-img-content--content--margin--xxl};
        --content-container--margin-right: #{$split-img-content--content--margin--xxl};
    }

    &.split-img-content-layout--edge-to-edge { // full-width of the viewport
        width: 100%;
        --content-container--margin-left: 0;
        --content-container--margin-right: 0;
        --content-container--max-width: none;
        @include media-breakpoint-up(md) {
            --content-container--max-width: 83%;
        }
        @include media-breakpoint-up(lg) {
            --content-container--margin-left: #{$split-img-content--content--margin--lg};
            --content-container--margin-right: auto;
            --content-container--max-width: none;
        }
        @include media-breakpoint-up(xl) {
            --content-container--margin-left: #{$split-img-content--content--margin--xl};
        }
        @include media-breakpoint-up(xxl) {
            width: 100%;
            --content-container--margin-left: #{$split-img-content--content--margin--xxl};
            --content-container--max-width: calc((#{map-get($container-max-widths, xxl)} / 2) - #{$split-img-content--content--margin--xxl});
        }
    }
}

// section with image background
.section-with-bg-img {
    position: relative;
    .section-with-bg-img--bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            height: calc(100% + 1px);
            object-fit: cover;
        }
    }
    .section-with-bg-img--content {
        position: relative;
        z-index: 2;
        width: 100%;
    }
    &.full-height {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    }
}

// Panel behind widescreen (min-ratio) media element

.panel-img-overlap {
    --img-width: 50%;
    --content-width: 50%;
    --overlap: 120px;
    @include media-breakpoint-up(lg) {
        .panel-img-overlap--inner {
            display: flex;
            width: 100%;
            align-items: center;
            .panel-img-overlap--visual {
                position: relative;
                z-index: 2;
                width: calc(var(--img-width) + var(--overlap));
                .panel-img-overlap--visual--inner {
                    background-color: $dark;
                    figure.img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .panel-img-overlap--content {
                position: relative;
                z-index: 1;
                width: var(--content-width);
                align-self: stretch;
                .panel {
                    display: flex;
                    flex-direction: column;
                    min-height: 100%;
                    justify-content: center;
                }
            }
        }
        &:not(.panel-img-overlap--img-left) {
            .panel-img-overlap--visual {
                order: 2;
                margin-left: calc(-1 * var(--overlap));
            }
            .panel-img-overlap--content {
                order: 1;
                .panel {
                    padding-right: calc(var(--overlap) + 30px) !important;
                }
            }
        }
        &--img-left {
            .panel-img-overlap--visual {
                order: 1;
                margin-right: calc(-1 * var(--overlap));
            }
            .panel-img-overlap--content {
                order: 2;
                .panel {
                    padding-left: calc(var(--overlap) + 30px) !important;
                }
            }
        }
    }
}
