@mixin non-retina-font-smoothing-antialiased {
    @media(-webkit-min-device-pixel-ratio: 1) {
        -webkit-font-smoothing: antialiased;
    }
}

// theme mixins

@mixin on-light-theme-vars {
    // basic
    --theme--color: #{$brand--black};
    color: var(--theme--color);
    // for headings, quotes etc
    --theme--color--emphasis: #{$brand--black};
    --theme--color--emphasis--invert: #{$brand--white};
    // for minor text, helper text etc
    --theme--color--subtle: #{$brand--grey};
    // links
    --theme--link--color: #{$brand--sky-blue};
    --theme--link--text-decoration: #{$link-decoration};
    --theme--link--text-decoration--color: transparent;
    --theme--link--text-decoration--style: dotted;
    --theme--link--color--hover: #{darken($brand--sky-blue, 10%)};
    --theme--link--text-decoration--hover: #{$link-hover-decoration};
    --theme--link--text-decoration--color--hover: #{darken($brand--sky-blue, 10%)};
    --theme--link--text-decoration--style--hover: solid;
    // icons, bullets, rules etc
    --theme--decor--color: #{$brand--sky-blue};
    --theme--accent--color: #{$brand--sky-blue};
    --theme--rule--color: #{$brand--light-grey};
    // button - standard
    --theme--btn--bg-color: var(--brand--accent-color);
    --theme--btn--border-color: var(--brand--accent-color);
    --theme--btn--color: #{$brand--white};
    --theme--btn--bg-color--hover: color-mix(in srgb, black 50%, var(--brand--accent-color));
    --theme--btn--border-color--hover: color-mix(in srgb, black 50%, var(--brand--accent-color));
    --theme--btn--color--hover: #{$brand--white};
    // button - primary
    --theme--btn--primary--bg-color: #{$brand--red};
    --theme--btn--primary--border-color: #{$brand--red};
    --theme--btn--primary--color: #{$brand--white};
    --theme--btn--primary--bg-color--hover: #{darken($brand--red, 10%)};
    --theme--btn--primary--border-color--hover: #{darken($brand--red, 10%)};
    --theme--btn--primary--color--hover: #{$brand--white};
    // button - secondary
    --theme--btn--secondary--bg-color: var(--brand--accent-color);
    --theme--btn--secondary--border-color: var(--brand--accent-color);
    --theme--btn--secondary--color: #{$brand--white};
    --theme--btn--secondary--bg-color--hover: color-mix(in srgb, black 50%, var(--brand--accent-color));
    --theme--btn--secondary--border-color--hover: color-mix(in srgb, black 50%, var(--brand--accent-color));
    --theme--btn--secondary--color--hover: #{$brand--white};
    // form elements
    --theme--sub-form--bg-color: #{$color--lightest-blue};
    --theme--form--checked--bg-color: var(--theme--accent--color);
    --theme--form--checked--border-color: var(--theme--accent--color);
    --theme--form--checkbox--indicator: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' preserveAspectRatio='none'%3E%3Cpath fill='%23ffffff' d='M447.9 142.5l-23.2 22L181 395.3l-22 20.8-22-20.8L23.2 287.6 0 265.6l44-46.5 23.2 22L159 328 380.7 118l23.2-22 44 46.5z'/%3E%3C/svg%3E");
    --theme--form--radio--indicator: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}

@mixin on-dark-theme-vars {
    // basic
    --theme--color: #{$brand--white};
    color: var(--theme--color);
    // for headings, quotes etc
    --theme--color--emphasis: #{$brand--white};
    --theme--color--emphasis--invert: #{$brand--black};
    // for minor text, helper text etc
    --theme--color--subtle: #{$brand--light-grey};
    // links
    --theme--link--color: #{$brand--white};
    --theme--link--text-decoration: #{$link-decoration};
    --theme--link--text-decoration--color: rgba(#{to-rgb($brand--white)}, 0.6);
    --theme--link--text-decoration--style: dotted;
    --theme--link--color--hover: #{$brand--white};
    --theme--link--text-decoration--hover: #{$link-hover-decoration};
    --theme--link--text-decoration--color--hover: #{$brand--white};
    --theme--link--text-decoration--style--hover: solid;
    // icons, bullets, rules etc
    --theme--decor--color: #{$brand--yellow};
    --theme--accent--color: #{$brand--sky-blue};
    --theme--rule--color: #{rgba($brand--white, 0.4)};
    // button - standard
    --theme--btn--bg-color: var(--brand--accent-color);
    --theme--btn--border-color: var(--brand--accent-color);
    --theme--btn--color: #{$brand--white};
    --theme--btn--bg-color--hover: color-mix(in srgb, black 50%, var(--brand--accent-color));
    --theme--btn--border-color--hover: #{$brand--white};
    --theme--btn--color--hover: #{$brand--white};
    // button - primary
    --theme--btn--primary--bg-color: #{$brand--red};
    --theme--btn--primary--border-color: #{$brand--red};
    --theme--btn--primary--color: #{$brand--white};
    --theme--btn--primary--bg-color--hover: #{darken($brand--red, 10%)};
    --theme--btn--primary--border-color--hover: #{$brand--white};
    --theme--btn--primary--color--hover: #{$brand--white};
    // button - secondary
    --theme--btn--secondary--bg-color: #{$brand--yellow};
    --theme--btn--secondary--border-color: #{$brand--yellow};
    --theme--btn--secondary--color: #{$brand--black};
    --theme--btn--secondary--bg-color--hover: #{darken($brand--yellow, 10%)};
    --theme--btn--secondary--border-color--hover: #{$brand--white};
    --theme--btn--secondary--color--hover: #{$brand--black};
    // form elements
    --theme--sub-form--bg-color: #{$color--lightest-blue};
    --theme--form--checked--bg-color: #{$brand--yellow};
    --theme--form--checked--border-color: #{$brand--yellow};
    --theme--form--checkbox--indicator: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' preserveAspectRatio='none'%3E%3Cpath fill='%23002f5f' d='M447.9 142.5l-23.2 22L181 395.3l-22 20.8-22-20.8L23.2 287.6 0 265.6l44-46.5 23.2 22L159 328 380.7 118l23.2-22 44 46.5z'/%3E%3C/svg%3E");
    --theme--form--radio--indicator: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23002f5f'/%3e%3c/svg%3e");
}

@mixin visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}
