#header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 10;
    height: 0;
    pointer-events: none;

    header {
        position: relative;
        height: calc(var(--top-utility-bar--height) + var(--header--height));
        transition: transform 300ms ease-in-out;
        will-change: transform;

        .header-hidden:not(.mobile-menu-active) & {
            transform: translate3d(0,calc(-1 * (var(--top-utility-bar--height) + var(--header--height))),0);
        }

        #skip-nav {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: var(--top-utility-bar--height);
            left: 0;
            z-index: 2;
            width: 100%;
            height: var(--header--height);
            background-color: white;
            font-size: 1rem;
            line-height: 1rem;
            text-decoration: underline;
            opacity: 0;
            pointer-events: none;
            transition: opacity 200ms linear;

            &:focus {
                opacity: 1;
                pointer-events: all;
            }
        }

        .header--content {
            display: flex;
            width: 100%;
            height: var(--header--height);
            justify-content: space-between;
            align-items: center;
            position: relative;
            pointer-events: all;
            .header--content--logo {
                display: block;
                order: 2;
                width: 160px;
                height: 30px;
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    .header--content--logo--crown, .header--content--logo--arrows {
                        display: none;
                    }
                    .header--content--logo--text {
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: url(../../img/header/header-logo--text.png) center center no-repeat;
                        background-size: contain;
                    }
                }
            }
            .header--content--search-toggle {
                order: 1;
                width: 50px;
                margin-left: calc(0px - #{$input-btn-padding-x});
            }
            .header--content--menu-toggle {
                order: 3;
                width: 50px;
                text-align: right;
                button {
                    .bars {
                        display: block;
                        width: 23px;
                        height: 23px;
                        position: relative;
                        transition: transform 200ms ease-in-out;
                        will-change: transform;

                        i {
                            position: absolute;
                            top: 4px;
                            left: 0;
                            width: 23px;
                            height: 2px;
                            border-radius: 1px;
                            background-color: var(--theme--link--color);
                            transition: transform 200ms ease-in-out, width 200ms ease-in-out, opacity 200ms ease-in-out;
                            will-change: transform;

                            &:nth-child(1) {
                                transform-origin: left top;
                            }

                            &:nth-child(2) {
                                transform-origin: center center;
                                top: 11px;
                            }

                            &:nth-child(3) {
                                transform-origin: left bottom;
                                top: 18px;
                            }
                        }
                    }
                    @include media-breakpoint-down(lg) {
                        .header--menu-toggle--label {
                            position: absolute !important;
                            width: 1px !important;
                            height: 1px !important;
                            padding: 0 !important;
                            margin: -1px !important;
                            overflow: hidden !important;
                            clip: rect(0, 0, 0, 0) !important;
                            white-space: nowrap !important;
                            border: 0 !important;
                        }
                    }
                    &[aria-expanded="true"] {
                        .bars {
                            transform: rotate(-90deg) translate3d(0,-0.5px,0);
                            i {
                                &:nth-child(1) {
                                    transform: translate3d(5px,-1px,0) rotateZ(45deg);
                                }
                                &:nth-child(2) {
                                    transform: scaleX(0);
                                }
                                &:nth-child(3) {
                                    transform: translate3d(5px,1px,0) rotateZ(-45deg);
                                }
                            }
                        }
                    }
                }
            }
        }

        .header--content--search {
            display: block;
            position: fixed;
            z-index: 10;
            top: 0;
            left: 0;
            right: 0;
            height: var(--vpHeight);
            overflow: hidden;
            pointer-events: none;
            will-change: transform;
            .header--content--search--backdrop {
                display: block;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
                background-color: rgba($brand--blue, 0.6);
                opacity: 0;
                will-change: opacity;
                transition: opacity 250ms linear;
            }
            .header--content--search--inner {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: min(100%, 500px);
                height: 100%;
                transform: translateX(-110%);
                transition: transform 250ms ease-in-out;
                will-change: transform;
                pointer-events: none;
                overflow-y: auto;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
                & > .container {
                    padding: 1rem var(--edge-padding) var(--edge-padding);
                    .header--search--inputs {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        input[type="search"] {
                            flex: 1 1 auto;
                        }
                        input[type="submit"], button {
                            flex: 0 0 auto;
                        }
                    }
                }
            }
            &.search-form--active {
                .header--content--search--backdrop {
                    pointer-events: all;
                    opacity: 1;
                }
                .header--content--search--inner {
                    transform: translateX(0);
                    pointer-events: all;
                }
            }
            &[aria-hidden="true"] {
                display: none;
            }
        }

        @include media-breakpoint-up(lg) {

            .header--utility-bar {
                display: flex;
                width: 100%;
                height: var(--top-utility-bar--height);
                align-items: center;
                pointer-events: all;

                .header--utility-bar--links {
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    padding-right: 100px;
                    ul {
                        display: flex;
                        width: 100%;
                        justify-content: flex-end;
                        li {
                            margin: 0 !important;
                            font-family: $headings-font-family;
                            font-weight: $headings-font-weight;
                            font-size: 1rem;
                            text-transform: uppercase;
                            .btn-link {
                                display: inline-flex;
                                gap: .5rem;
                                align-items: center;
                                .basket-indicator {
                                    position: absolute;
                                    top: 1.8em;
                                    left: 1.8em;
                                }
                            }
                        }
                    }
                }
            }

            .header--content {
                justify-content: flex-end;
                padding-left: $header--desktop-logo-allowance;
                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: $header--desktop-logo-allowance;
                    right: 0;
                    height: 1px;
                    background-color: rgba($brand--white, 0.2);
                }
                .header--content--logo {
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    transform: translateY(calc(0px - var(--top-utility-bar--height)));
                    width: 224px;
                    height: calc(var(--top-utility-bar--height) + var(--header--height));
                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 100%;
                        left: 50%;
                        margin-left: -130px;
                        width: 0;
                        height: 0;
                        border-left: 130px solid transparent;
                        border-right: 130px solid transparent;
                        border-top: 88px solid $brand--blue;
                        will-change: transform;
                        transition: transform 500ms ease-in-out;
                    }
                    a {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        height: 155px;
                        margin-top: 12px;
                        .header--content--logo--crown {
                            display: block;
                            margin-bottom: 5px;
                            content: '';
                            width: 50px;
                            height: 42px;
                            background: url(../../img/header/header-logo--crown.png) center center no-repeat;
                            background-size: contain;
                            will-change: opacity, transform;
                            transform-origin: 50% 100%;
                            transition: all 500ms ease-in-out;
                            transition-delay: 200ms;
                        }
                        .header--content--logo--text {
                            width: 174px;
                            height: 33px;
                            will-change: transform;
                        }
                        .header--content--logo--arrows {
                            display: block;
                            margin-top: -8px;
                            content: '';
                            width: 224px;
                            height: 86px;
                            background: url(../../img/header/header-logo--arrows.png) center center no-repeat;
                            background-size: contain;
                            will-change: opacity, transform;
                            transition: all 500ms ease-in-out;
                            transition-delay: 400ms;
                            transform-origin: 50% 100%;
                        }
                    }
                }
                .header--content--search-toggle {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: auto;
                    transform: translateY(-46px);
                    margin: 0;
                    margin-right: calc(0px - #{$input-btn-padding-x});
                    .btn-link {
                        font-family: $headings-font-family;
                        font-weight: $headings-font-weight;
                        font-size: 1rem;
                        text-transform: uppercase;
                        display: inline-flex;
                        gap: .5rem;
                        align-items: center;
                    }
                }
            }

            .header--content--search {
                .header--content--search--inner {
                    left: auto;
                    right: 0;
                    width: 500px;
                    transform: translateX(110%);
                }
                &.search-form--active {
                    .header--content--search--inner {
                        transform: translateX(0);
                    }
                }
            }

            // minimized layout when page scrolled
            .minimal-header & {

                .header--content {
                    .header--content--logo {
                        &:before {
                            transform: translateY(-100%);
                        }
                        a {
                            pointer-events: none;
                            .header--content--logo--crown {
                                transform: scale(0);
                                opacity: 0;
                                transition-delay: 0ms;
                            }
                            .header--content--logo--text {
                                pointer-events: all;
                                transform: translate3d(0,0,0);
                            }
                            .header--content--logo--arrows {
                                transform-origin: 50% 0;
                                transform: scale(0);
                                opacity: 0;
                                transition-delay: 0ms;
                            }
                        }
                    }
                }

            }

        }
    }

    // RAFCT styling changes

    body.rafct & {

        header {
            --theme--link--color: #{$brand--blue};

            .header--content {

                @include media-breakpoint-up(lg) {
                    &:before {
                        background-color: $brand--light-grey;
                    }
                }

                .header--content--logo {
                    width: 160px;
                    height: var(--header--height);
                    @include media-breakpoint-up(lg) {
                        transform: translateY(calc(0px - var(--top-utility-bar--height)));
                        width: 224px;
                        height: calc(var(--top-utility-bar--height) + var(--header--height));
                        &:before {
                            display: none;
                            content: none;
                        }
                    }
                    a {
                        margin: 0;
                        width: 100%;
                        height: 100%;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }

            }
        }

    }

}
